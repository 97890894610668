import {
  mabadiveDiverWebConfig_DEV,
  mabadiveDiverWebConfig_DEV_IP,
  mabadiveDiverWebConfig_DIST,
} from './env';
import { getEnvIdFromUrl } from './getEnvIdFromUrl.service';
import { MabadiveProWebConfig } from './model';

const envId = getEnvIdFromUrl();

const isDevIP = process.env.REACT_APP_STAGE === 'dev-ip'; // to debug on local network: http://192.168.1.100:3009 (chercher "localIp =")
const isDev = process.env.REACT_APP_STAGE === 'dev' || envId === 'dev';

export const appWebConfig: MabadiveProWebConfig = isDevIP
  ? mabadiveDiverWebConfig_DEV_IP
  : isDev
  ? mabadiveDiverWebConfig_DEV
  : mabadiveDiverWebConfig_DIST;
